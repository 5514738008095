import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/about/Content";
import Team from "../sections/about/Team";
import CTA from "../sections/about/CTA";
import {graphql} from "gatsby";

const About = () => {
  return (
    <>
      <PageWrapper>
        <Hero title="About us">
          Create custom landing pages with Omega that converts more visitors
          than any website.
        </Hero>
        <Content />
        <Team />
        <CTA />
      </PageWrapper>
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "contact", "faq", "feature", "header", "hero", "newsletter", "pricing", "testimonial"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default About;
